import React from "react"
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"
import {
	CalendarPicker,
	CalendarPickerProps,
	LocalizationProvider,
	PickersDay,
	PickersDayProps,
} from "@mui/x-date-pickers"
import styled from "styled-components"
import {differenceInCalendarDays} from "date-fns"

import {locales} from "./locales"

function sameDay(d1: Date, d2: Date) {
	return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()
}

function Calendar({
	locale,
	minDate,
	maxDate,
	...props
}: {locale: string; minDate: Date; maxDate: Date} & CalendarPickerProps<Date>) {
	const renderDay = (day: Date, _selectedDays: Date[], props: PickersDayProps<Date>) => {
		if (
			differenceInCalendarDays(day, minDate) < 0 ||
			differenceInCalendarDays(day, maxDate) > 0 ||
			props.outsideCurrentMonth
		) {
			return <DisabledPickersDay {...props} />
		}

		return (
			<DayContainer isLastDay={sameDay(day, maxDate)} isFirstDay={sameDay(day, minDate)}>
				<CustomPickersDay {...props} isLastDay={sameDay(day, maxDate)} isFirstDay={sameDay(day, minDate)} />
			</DayContainer>
		)
	}
	return (
		<LocalizationProvider adapterLocale={locales[locale]} dateAdapter={AdapterDateFns}>
			<CalendarPicker<Date>
				{...props}
				minDate={minDate}
				maxDate={maxDate}
				renderDay={renderDay}
				disableHighlightToday={true}
			/>
		</LocalizationProvider>
	)
}

const DisabledPickersDay = styled(PickersDay)`
	border-radius: 0;
`

const CustomPickersDay = styled(PickersDay)<{isLastDay: boolean; isFirstDay: boolean}>`
	color: ${(props) => (props.selected ? "white" : props.theme.palette.common.black)} !important;
	border-color: ${(props) => props.theme.palette.primary.main} !important;
	background-color: ${(props) => (props.selected ? props.theme.palette.primary.main : "#EDEFF1")} !important;
	border-radius: 50%;
`

const DayContainer = styled.div<{isLastDay: boolean; isFirstDay: boolean; selected?: boolean}>`
	background-color: ${(props) => (props.selected ? props.theme.palette.primary.main : "#EDEFF1")};
	border-top-right-radius: ${(props) => (props.isLastDay ? "50%" : "0")};
	border-bottom-right-radius: ${(props) => (props.isLastDay ? "50%" : "0")};
	border-top-left-radius: ${(props) => (props.isFirstDay ? "50%" : "0")};
	border-bottom-left-radius: ${(props) => (props.isFirstDay ? "50%" : "0")};
`

export {Calendar}
