import React, {useEffect, useState} from "react"
import {paymentPlanAPI, setRegionFromRegionNumber, setRegionFromSecureCode} from "@indebted/api"
import {LoadingPage} from "@indebted/components/Loading"
import {ErrorPage} from "@indebted/components/Error"
import {track} from "@indebted/analytics"
import {useParams, useLocation} from "react-router-dom"

import {ManagePaymentPlan} from "../ManagePaymentPlan/ManagePaymentPlan"

import {PaymentPlanCompleted} from "./PaymentPlanCompleted"
import {PaymentPlanInvalidated} from "./PaymentPlanInvalidated"
import {PaymentPlanCancelled} from "./PaymentPlanCancelled"
import {PaymentPlanCancelledByCustomer} from "./PaymentPlanCancelledByCustomer"
import {PaymentPlanAutomaticallyCancelled} from "./PaymentPlanAutomaticallyCancelled"
import {PaymentPlanMandateRevoked} from "./PaymentPlanMandateRevoked"
import {PaymentPlanInitiatedOrSubmitted} from "./PaymentPlanInitiatedOrSubmitted"

function PaymentPlanStatusWithRegionPage({region}) {
	setRegionFromRegionNumber(region)
	return PaymentPlanStatusPage()
}

function PaymentPlanStatusWithSecureCodePage() {
	const {secureCode} = useParams()
	setRegionFromSecureCode(secureCode)

	return PaymentPlanStatusPage()
}

function PaymentPlanStatusPage() {
	const {paymentPlanID, secureCode} = useParams()
	const location = useLocation()
	const i18n = location?.state?.I18n
	const [Component, setComponent] = useState(<LoadingPage I18n={i18n} />)

	useEffect(() => {
		paymentPlanAPI
			.pollConfirmation(paymentPlanID)
			.then((result) => {
				track("PPConfirmPageViewed", {
					Status: result.Status,
					SecureCode: secureCode,
				})
				const StatusComponent = paymentPlanStatusComponent(result.Status)
				const props = {...result, PaymentPlanID: paymentPlanID}

				setComponent(<StatusComponent {...props} />)
			})
			.catch((error) => {
				setComponent(<ErrorPage message={error.Message} error={error} />)
			})
	}, [paymentPlanID, secureCode])

	return Component
}

function paymentPlanStatusComponent(status) {
	const map = {
		SetupSucceeded: ManagePaymentPlan,
		Invalidated: PaymentPlanInvalidated,
		Completed: PaymentPlanCompleted,
		Cancelled: PaymentPlanCancelled,
		CancelledByCustomer: PaymentPlanCancelledByCustomer,
		AutomaticallyCancelled: PaymentPlanAutomaticallyCancelled,
		MandateRevoked: PaymentPlanMandateRevoked,
		Initiated: PaymentPlanInitiatedOrSubmitted,
		IntentSubmitted: PaymentPlanInitiatedOrSubmitted,
	}

	return map[status] || (() => <ErrorPage message="Unknown payment plan status" />)
}

export {PaymentPlanStatusWithRegionPage, PaymentPlanStatusWithSecureCodePage}
