import React, {useState} from "react"
import {PaymentElement, Elements, useElements} from "@stripe/react-stripe-js"
import {Button, FormControl, FormHelperText, InputLabel, Link, OutlinedInput, Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {useNotification} from "@indebted/components/Notification"
import {TermsOfDiscountOffer} from "@indebted/components/TermsOfDiscountOffer"
import {BankAccountIcon} from "@indebted/assets"

import "regenerator-runtime/runtime"
import {Api} from "./api"

function OXXOForm({stripe, locale, ...props}) {
	return (
		<Elements stripe={stripe} options={{locale, clientSecret: props.clientSecret, paymentMethodCreation: "manual"}}>
			<InnerForm stripe={stripe} {...props} />
		</Elements>
	)
}

function InnerForm({
	stripe,
	onSubmit,
	i18n,
	termsOfDiscountOffer,
	morePaymentMethodsAvailable,
	paymentMethodSelectionLink,
}) {
	const {notification} = useNotification()
	const [submitting, setSubmitting] = useState(false)
	const [form, setForm] = useState({Email: "", Name: ""})
	const elements = useElements()
	const [isStripeElementReady, setStripeElementReady] = useState(false)

	const handleInput =
		(name) =>
		({target: {value}}) => {
			setForm({...form, [name]: value})
		}

	const stripeAPI = Api({
		stripeSDK: stripe,
		form,
		elements,
	})

	// eslint-disable-next-line complexity
	const submit = async (event) => {
		event.preventDefault()

		// See https://stripe.com/docs/stripe-js/react#useelements-hook
		if (!stripe || !elements) {
			return
		}

		const emailValidationRegex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

		if (form.Name.trim() == "" || !form.Email.match(emailValidationRegex)) {
			notification.error(i18n.FormErrorsEmailNameValidation, 5000)
			return
		}

		setSubmitting(true)
		try {
			await elements.submit()
			await onSubmit({stripeAPI})
		} catch {
			setSubmitting(false)
		}
	}

	return (
		<form onSubmit={submit}>
			<Grid container direction="column" alignItems="stretch" spacing={5}>
				<Grid container>
					<Grid container direction="column" alignItems="stretch" spacing={5} style={{flex: 1}}>
						{isStripeElementReady ? (
							<>
								<Grid>
									<FormControl variant="outlined" fullWidth={true}>
										<InputLabel htmlFor="fullname">{i18n.FormFullNameLabel}</InputLabel>
										<OutlinedInput
											id="fullname"
											type="text"
											label={i18n.FormFullNameLabel}
											value={form.Name}
											onChange={handleInput("Name")}
											required
											autoFocus
										/>
									</FormControl>
								</Grid>
								<Grid>
									<FormControl variant="outlined" fullWidth={true}>
										<InputLabel htmlFor="email">{i18n.FormEmailLabel}</InputLabel>
										<OutlinedInput
											id="email"
											label={i18n.FormEmailLabel}
											type="email"
											value={form.Email}
											onChange={handleInput("Email")}
											required
										/>
										<FormHelperText>{i18n.FormEmailHelp}</FormHelperText>
									</FormControl>
								</Grid>
							</>
						) : null}
						<Grid style={{display: "none"}}>
							<PaymentElement
								options={{
									terms: {
										card: "never",
										auBecsDebit: "never",
										usBankAccount: "never",
									},
									fields: {
										billingDetails: {
											name: "never",
											email: "never",
										},
									},
								}}
								onReady={() => {
									setStripeElementReady(true)
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid>
					<Typography variant="caption" color="textSecondary" component="p" align="center">
						{i18n.FormTermsAndConditionsText}{" "}
						<Link href={i18n.FormTermsAndConditionsLink} target="_blank" rel="noopener noreferrer">
							{i18n.FormTermsAndConditionsLinkText}
						</Link>
						{termsOfDiscountOffer?.AndText}
						<TermsOfDiscountOffer linkLabel={termsOfDiscountOffer?.ModalLinkLabel} />
					</Typography>
				</Grid>
				<Grid>
					<Button fullWidth type="submit" disabled={!stripe || submitting}>
						{i18n.FormButtonLabel}
					</Button>
				</Grid>
				{morePaymentMethodsAvailable && (
					<Grid style={{paddingTop: "3px", paddingRight: "5px", display: "flex", alignItems: "flex-start"}}>
						<BankAccountIcon />
						<Typography style={{marginLeft: "5px"}}>
							{i18n.FormPaymentMethodSelectionText}
							<Link href={paymentMethodSelectionLink} rel="noopener noreferrer">
								{i18n.FormPaymentMethodSelectionLinkText}
							</Link>
						</Typography>
					</Grid>
				)}
			</Grid>
		</form>
	)
}

export {OXXOForm}
