import React, {useRef} from "react"
import styled from "styled-components"
import {TextField, FormControl, InputAdornment} from "@mui/material"
import NumberFormat from "react-number-format"

function AmountInput(props) {
	const inputRef = useRef(null)
	return (
		<FormControl variant="outlined" fullWidth={true}>
			<StyledInput
				variant="outlined"
				type="text"
				autoFocus
				ref={inputRef}
				{...props}
				onChange={props.onChange}
				inputProps={{
					inputMode: "numeric",
				}}
				onClick={(event) => {
					event.preventDefault()
					inputRef.current?.querySelector("input")?.focus()
				}}
				InputProps={{
					startAdornment: <InputAdornment position="start">{props.currency}</InputAdornment>,
					inputComponent: NumberFormatCustom,
					className: props?.classes?.input,
				}}
			/>
		</FormControl>
	)
}

function NumberFormatCustom(props) {
	const {inputRef, onChange, ...other} = props

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.floatValue,
					},
				})
			}}
			thousandSeparator={true}
			decimalScale={0}
			// Currently there is no use case for allowing negative numbers in the amount input.
			allowNegative={false}
		/>
	)
}

const StyledInput = styled(TextField)`
	input {
		text-align: left;
		margin-right: 0px;
		padding: 18px 0px;
		color: #474cde;
		font-weight: 600;
		font-size: 18px;
	}
`

export {AmountInput}
